<div class="home">
  <div class="home-container">
    <div class="home-title">
      <div class="home-title-logo">
        <div class="home-title-logo-icon">
          <img src="assets/media/iconLogo.svg" alt="" />
        </div>
        <div class="home-title-logo-text">
          <img src="assets/media/textLogo.svg" alt="" />
          <div class="home-title-logo-text-subtitle">
            Where books come to life
          </div>
        </div>
      </div>
    </div>
    <div class="home-video">
      <div class="home-video-title">Building the next book thing</div>

      <div class="video-wrap">
        <div class="descAndVideo">
          <div class="description">1:54 min Pitch</div>
          <div class="home-video-video">
            <iframe width="595" height="313" src="https://www.youtube.com/embed/0tjyhwnwlIs?si=IsOGvzRyepCl7_Gj"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
            <!-- <video width="100%" height="auto" controls>
              <source [src]="videoUrl" type="video/mp4" />
              Your browser does not support the video tag.
            </video> -->
          </div>
        </div>


        <div class="descAndVideo">
          <div class="description">2:59 min SuperDemo</div>
          <div class="home-video-video">
            <iframe width="595" height="313" src="https://www.youtube.com/embed/qsWhItK_n7o?si=ix0-0UaMR1nelMYx&rel=0"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
            <!-- <video width="100%" height="auto" controls>
            <source [src]="videoUrl" type="video/mp4" />
            Your browser does not support the video tag.
          </video> -->
          </div>
        </div>

      </div>

    </div>



    <div class="home-button">
      <button class="home-button-btn">
        <a href="https://www.debookmagickey.com" target="_blank">3333 MAGICKEYS to be part of history</a>
      </button>
    </div>
  </div>
</div>